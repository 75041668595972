<template>
  <v-form v-if="item" autocomplete="off">
    <v-row>
        <v-col md=7>

          <v-text-field
            id="firstNameInput"
            name="firstName"
            label="Nome"
            v-model="item.first_name"
          ></v-text-field>

          <v-text-field
            id="lastNameInput"
            name="lastName"
            label="Sobrenome"
            v-model="item.last_name"
          ></v-text-field>

          <v-text-field
            id="emailInput"
            name="email"
            label="Email"
            v-model="item.email"
          ></v-text-field>
          
          <v-text-field
            id="phoneInput"
            name="phone"
            label="Telefone"
            v-model="item.phone"
            return-masked-value
            v-mask="'(##) ####-#####'"
          ></v-text-field>

        <v-row v-if="$route.params.id">
          <v-col md=10>
            <v-combobox
              v-model="item.roles"
              :items="this.$store.getters.findAll('role')"
              label="Papéis"
              item-text="name"
              item-value="value"
              multiple
              outlined
              dense
            ></v-combobox>
          </v-col>
          
          <v-col md=2>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="addRoleButtonSm"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="default"
                      v-bind="attrs"
                      v-on="on"
                      link :to="'/role/add'"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>

                    </v-btn>
                </template>
                <span>Adicionar papel de usuário</span>
              </v-tooltip>

          </v-col>
        </v-row>
        <v-select
          v-if="$route.params.id &&
                item.roles &&
                item.roles.map(i => {return i.value}).includes('lawyer')"
          id="officeInput"
          name="office"
          label="Escritório"
          v-model="item.office"
          item-text="name"
          item-value="id"
          :items="offices"
        ></v-select>

        </v-col>

        <v-col>
          &nbsp;
        </v-col>

    </v-row>

    <v-row>

        <back-button
          view="small"
        ></back-button>

        <save-button view="big" @save="submit()">
        </save-button>

    </v-row>


  </v-form>
  
</template>

<script>
    import BackButton from '../../components/BackButton.vue'
  import SaveButton from '../../components/SaveButton.vue'
  const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'UserForm',
    components: {
      BackButton,
      SaveButton
    },
    props: ['item', 'roles', 'offices'],
    watch: {
      'item.roles': function () {
        if (
          this.item.roles &&
          !this.item.roles.map(i => {return i.value}).includes('lawyer')
        ) {
          delete this.item.office
        }
      }
    },
    methods: {
      submit () {
        return EventBus.$emit('user-submitted')
      }
    }
  }
</script>
