<template>
 <v-card
    v-if="item"
    class="mx-auto"
    tile
  >
      <v-row>
        <v-col md="2">
                <br>
                <p class="title">{{item.first_name}} {{item.last_name}}</p>
                <p>{{item.email}}</p>
                <p>{{item.phone}}</p>
        </v-col>
        <v-col md="6">

        <v-timeline dense>
            <v-timeline-item
              v-for="event in item.timeline"
              v-bind:key="event.refcode"
              :color="dictionary[event.value] && dictionary[event.value].color ? dictionary[event.value].color : 'blue'"
              :icon="dictionary[event.value] && dictionary[event.value].icon ? dictionary[event.value].icon : ''"
            >
            {{event.value}}
            <p>{{event.description}}<br>
            <small>{{event.time}}</small>
            </p>
          </v-timeline-item>
        </v-timeline>        
        </v-col>
      </v-row>
  </v-card>
</template>
<script>

export default {
    name: 'UserDetail',
    props: ['item'],
    computed: {
        dictionary: function() {
            return this.$store.getters.dictionary
        },
    }
}
</script>