<template>
<v-container fluid>
  <v-row>
      <action-bar entity="user"></action-bar>
      <v-col cols=11>
        <v-card>
          <v-card-title>
            <h2 class="title">Usuários</h2>
          </v-card-title>
        </v-card>


      <div v-if="items">
            <default-table
              v-if="!$route.params.action"
              name="Usuário"
              endpoint="user"
              entity="user"
              :items="items"
              :headers="headers"
              :actions="['edit', 'delete']"
              :statusKeys="[]"
            >
            </default-table>
      </div>      

            
      <div v-if="item">
            <raw-api-response
              :raw="raw_item"
              v-if="$route.params.action === 'dev' && $route.params.id"
            ></raw-api-response>

            <user-form :roles="roles" :item="item" v-if="($route.params.action === 'edit' && $route.params.id) || ($route.params.action === 'create' && !$route.params.id)"></user-form>
            
            <user-detail :item="item" v-if="$route.params.action === 'view'"></user-detail>
      </div>
      
      <div v-if="$store.state.loading">  
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            :src="'/static/illustrations/' + image + '.svg'"
            width="100%"
            icon
          />
      </div>

    </v-col>
  </v-row>
</v-container>
</template>
<script>

const mixins = require('../../addons/mixins.js')
const {displayUser, processUser, userModel, userSchema} = require('../descriptors.js')

import ActionBar from '../../components/ActionBar'
import UserForm from '../components/UserForm'
import UserDetail from '../components/UserDetail'
import DefaultTable from '../../components/DefaultTable'
import RawApiResponse from '../components/RawApiResponse'

export default {
    name: 'User',
    components: {
        ActionBar,  
        DefaultTable,
        UserForm,
        UserDetail,
        RawApiResponse
    },
    mixins: [
      mixins.defaultEvents,
      mixins.defaultMethods,
      mixins.routeEvents,
      mixins.routeMethods,
    ],
    data() {
      return {
        'name': 'Usuário',
        'plural_name': 'Usuários',
        'image': '',
        'entity': 'user',
        'addAction': 'addEntity',
        'updateAction': 'updateEntity',
        'removeAction': 'removeEntity',
        'loadAction': 'getEntity',
        'loadAllAction': 'getEntities',
        'redir': '/user'
      }
    },
    mounted() {
      this.runLoad()
    },
    computed: {
      pk: function() {
        return this.$route.params.id
      },
      view: function() {
        return this.$route.params.action
      },
      roles: function() {
        return this.$store.getters.profileRoles
      },
      processed: function() {
        return processUser
      },
      schema: function() {
        return userSchema
      },
      raw_items: function() {
        return this.$store.getters.userList
      },
      items: function() {
        return this.$store.getters.userList.map(i => {return displayUser(i, this.$store.getters.dictionary)})
      },
      raw_item: function() {
        if (this.$route.params.action === 'create') {
          return userModel()
        }
        return this.$store.getters.currentUser
      },
      item: function() {
        if (this.$route.params.action === 'create') {
          return userModel()
        }
        return displayUser(this.$store.getters.currentUser, this.$store.getters.dictionary)
      },
      headers: function() {
        return [
          { text: 'Nome', value: 'first_name'},
          { text: 'Sobrenome', value: 'last_name'},
          { text: 'Email', value: 'email'},
          { text: 'Telefone', value: 'phone'},
          { text: 'Criado', value: 'created'},
          { text: 'Modificado', value: 'modified'},
          { text: 'Ações', sortable: false }
        ]
      }
    },
    methods: {
      // HOOKS
      entitySelected() {
        const pk = this.$route.params.id
        const p1 = this.$store.dispatch('getEntity', {entity:'user', pk})
        return p1.then(r => {
          this.$store.commit('setReady')
          return r
        })
      }
    }
  }
</script>